import React from "react";
import "./Header.scss";

function Header() {
  return (
    <div className="headerContainer">
      <div className="header__title">
        <h2>ftbl.world</h2>
      </div>
    </div>
  );
}

export default Header;
